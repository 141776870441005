/* header {
    background-color: rgb(51, 41, 87);
    position: sticky top;
    top: 0;
}
img {
    top: 0;
    right: 0;
    
    
}

.text-lightblue {
    color: rgb(244, 241, 255);
} */

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box  ;
}

.header {
    position: relative;
    width: 100%;
    height: 15vh;
    background-color: #D1461F;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* .header {
    min-width: 100%;
    height: 100%;
   
} */

.left {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    margin-left: 5vw;
}

.right {
    width: 100%;
    height: 100%;
    background-image: url('../MTBO.Banner.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
}

.left li {
    list-style: none;
}

.left a {
    text-decoration: none;
    color: rgba(241, 241, 241, 1);
    padding-right: 30px;
    text-transform: uppercase;
    transition: all .2s ease-in;
    font-size: 100%;
}

.left a:hover {
    color: #2D3548;
}