
  .Auth-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
  }
  
  .Auth-form {
    width: 420px;
    box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
    padding-top: 30px;
    padding-bottom: 20px;
    border-radius: 8px;
    background-color: white;
  }
  
  .Auth-form-content {
    padding-left: 12%;
    padding-right: 12%;
  }
  
  .Auth-form-title {
    text-align: center;
    margin-bottom: 1em;
    font-size: 24px;
    color: rgb(34, 34, 34);
    font-weight: 800;
  }
  
  label {
    font-size: 14px;
    font-weight: 600;
    color: rgb(34, 34, 34);
  }

  .button-container {
    margin-top: 2vw;
    display: flex;
    flex-direction: column;
    align-items: left; /* Align items to the left */
    margin-left: 10vw; /* Position the container 10vw from the left */
    height: 10vh; /* Full height to center vertically */
    justify-content: center; /* Center the buttons vertically */
  }

  .btn {
    width: 200px; /* Fixed width for the buttons */
    margin-bottom: 1vw; /* Space between buttons */
  }
  .button {
    width: 200px; /* Fixed width for the buttons */
    margin-bottom: 1vw; /* Space between buttons */
  }

  .logout-button {
    background-color: red;
    border-color: red;
  }

  .logout-button:hover {
    background-color: #D1461F; /* Change this to your desired hover color */
    border-color: #D1461F; /* Change this to your desired hover border color */
  }







  
  /* Resetting default margin, padding, and box-sizing */
body, h1, h2, p, ul, li, footer {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Basic styling for the body */
body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  background-color: #DDDDDD; /* Platinum */
  color: #0c0c0c; /* Night Black */
}

/* Styling for the homepage container */
.homepage {
  display: flex;
  flex-direction: column;
}
.homepagebeforelogin {
  display: flex;
  flex-direction: column;
  min-height: 50vw;
}


/* Styling for the header */
header {
  background-color: #011638; /* Deep Blue */
  color: #DDDDDD; /* Platinum */
  padding: 15px 20px;
  text-align: center;
}

/* Styling for the main content */
main {
  flex: 1 1;
}

/* General section styling */
section {
  margin-bottom: 20px;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

/* Styling for the hero section */
.hero {
  background-color: #011638; /* Vibrant Blue */
  text-align: center;
  margin: 3vw;
  margin-bottom: 0vw;
}

.hero h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #DDDDDD; /* Golden Yellow */
}

.hero p {
  font-size: 1.2rem;
  color: #DDDDDD; /* Soft Yellow */
}

/* Block types */
.block-container {
  display: flex;
  align-items: center;
}

/* Type 1: Both blocks 35vw, with 10vw gaps on either side and in the middle */




.block { /*standaard blok  */
  width: 50vw;
  margin:  3vw; /* 3vw gap on either side */
  background-color: #071c6d; /* Deep Blue */
  color: #DDDDDD; /* Platinum */
  padding: 3vw;
  border-radius: 8px;
}



/* Type 2: Left block 45vw, Right block 25vw, with 10vw gaps on either side and in the middle */


.type-2 .big-block {
  width: 75vw;
  margin:  3vw; /* 3vw gap on either side */
  background-color: #071c6d; /* Deep Blue */
  color: #dddddd; /* Platinum */
  padding: 3vw;
  border-radius: 8px;
}

.type-2 .small-block {
  width: 25vw;
  margin:  3vw; /* 3vw gap on either side */
  background-color: #f5cf5c; /* Deep Blue */
  color: #0c0c0c; /* Platinum */
  padding: 3vw;
  border-radius: 8px;
}

.type-2 .left-block img,
.type-2 .right-block img {
  max-width: 100%;
  height: auto;
  margin-left: 20px; /* Gap between image and text */
}


/* Styling for the footer */
footer {
  background-color: #0c0c0c; /* Night Black */
  color: #DDDDDD; /* Platinum */
  padding: 20px;
  text-align: center;
  bottom: 0;
  align-items: flex-end;
  min-height: 5vw;
}



footer .sponsors {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
  margin-top: 20px;
}

footer .sponsors img {
  max-width: 150px;
  height: auto;
  transition: transform 0.3s ease;
}

footer .sponsors img:hover {
  transform: scale(1.1);
}

/* header {
    background-color: rgb(51, 41, 87);
    position: sticky top;
    top: 0;
}
img {
    top: 0;
    right: 0;
    
    
}

.text-lightblue {
    color: rgb(244, 241, 255);
} */

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box  ;
}

.header {
    position: relative;
    width: 100%;
    height: 15vh;
    background-color: #D1461F;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* .header {
    min-width: 100%;
    height: 100%;
   
} */

.left {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    margin-left: 5vw;
}

.right {
    width: 100%;
    height: 100%;
    background-image: url(/static/media/MTBO.Banner.8a9fa02c.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
}

.left li {
    list-style: none;
}

.left a {
    text-decoration: none;
    color: rgba(241, 241, 241, 1);
    padding-right: 30px;
    text-transform: uppercase;
    transition: all .2s ease-in;
    font-size: 100%;
}

.left a:hover {
    color: #2D3548;
}
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box  ;
}

.HeaderBeforeLogin {
    position: relative;
    width: 100%;
    height: 15vh;
    background-color: #0c0c0c;
    display: flex;
    min-width: 100%;
    max-width: 100%;
    align-items: center;
}

/* .header {
    min-width: 100%;
    height: 100%;
   
} */

.HeaderBeforeLogin .left {
    display: flex;
    align-items: center;
    height: 100%;
   
    
    width: calc(100%/3);
}

.HeaderBeforeLogin .center {
    height: 70%;
    background-image: url("/static/media/Logo All Active IT met tekst wit.a4b0cd1c.png");
    background-size: contain; /* Keep the image within the container */
    background-repeat: no-repeat;
    background-position: center; /* Center the image horizontally and vertically */
    display: flex;
    width: calc(100%/3);
    justify-content: center;
    padding-right: 0%;
}

.HeaderBeforeLogin .center:hover {
    background-image: url("/static/media/Logo All Active IT met tekst licht geel.420e6395.png");
}


.HeaderBeforeLogin .right {
   
    height: 100%;
    background-image: none;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    text-align: right;
    width: calc(100%/3);
}


.HeaderBeforeLogin a {
    text-decoration: none;
    color: #dddddd;
    padding-right: 30px;
    text-transform: uppercase;
    transition: all .2s ease-in;
    font-size: clamp(20px, 1vw, 30px);
    width: 30vw;
}


.HeaderBeforeLogin a:hover{
    color: #f5cf5c;
}

.HeaderBeforeLogin .left a:hover {
    color: #f5cf5c;
}
