* {
    padding: 0;
    margin: 0;
    box-sizing: border-box  ;
}

.HeaderBeforeLogin {
    position: relative;
    width: 100%;
    height: 15vh;
    background-color: #0c0c0c;
    display: flex;
    min-width: 100%;
    max-width: 100%;
    align-items: center;
}

/* .header {
    min-width: 100%;
    height: 100%;
   
} */

.HeaderBeforeLogin .left {
    display: flex;
    align-items: center;
    height: 100%;
   
    
    width: calc(100%/3);
}

.HeaderBeforeLogin .center {
    height: 70%;
    background-image: url('../Images/Logo\ All\ Active\ IT\ met\ tekst\ wit.png');
    background-size: contain; /* Keep the image within the container */
    background-repeat: no-repeat;
    background-position: center; /* Center the image horizontally and vertically */
    display: flex;
    width: calc(100%/3);
    justify-content: center;
    padding-right: 0%;
}

.HeaderBeforeLogin .center:hover {
    background-image: url('../Images/Logo\ All\ Active\ IT\ met\ tekst\ licht\ geel.png');
}


.HeaderBeforeLogin .right {
   
    height: 100%;
    background-image: none;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    text-align: right;
    width: calc(100%/3);
}


.HeaderBeforeLogin a {
    text-decoration: none;
    color: #dddddd;
    padding-right: 30px;
    text-transform: uppercase;
    transition: all .2s ease-in;
    font-size: clamp(20px, 1vw, 30px);
    width: 30vw;
}


.HeaderBeforeLogin a:hover{
    color: #f5cf5c;
}

.HeaderBeforeLogin .left a:hover {
    color: #f5cf5c;
}